import { environment } from "src/environments/environment";

const cloudFrontURL = environment.cloudFrontURL;

export const CALENDAR_ICON_SVG = `${cloudFrontURL}/tml-isp-assets/calendar-icon.svg`;
export const USER_PLACEHODER = `${cloudFrontURL}/tml-isp-assets/assets/user-placeholder.png`;
export const TM_LOGO = `${cloudFrontURL}/tml-isp-assets/assets/tm-logo-blue.svg`;
export const TATA_LOGO_BLACK = `${cloudFrontURL}/tml-isp-assets/assets/tata_logo_black.svg`;
export const BANNER_LOGO = `${cloudFrontURL}/tml-isp-assets/assets/banner_connecting_logo.svg`;
export const ASCENDING_SVG = `${cloudFrontURL}/tml-isp-assets/assets/ascending.svg`;
export const ASCENDING_GREY_SVG = `${cloudFrontURL}/tml-isp-assets/assets/ascending-grey.svg`;
export const DESCENDING_SVG = `${cloudFrontURL}/tml-isp-assets/assets/descending.svg`;
export const DESCENDING_GREY_SVG = `${cloudFrontURL}/tml-isp-assets/assets/descending-grey.svg`;
export const AIR_FILTER_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/air_filter_default.png`;
export const AIR_FILTER_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/air_filter_amber.png`;
export const ENGINE_LAMP_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_lamp_default.png`;
export const ENGINE_LAMP_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_lamp_amber.png`;
export const REGENERATION_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/dpf_regeneration_default.png`;
export const REGENERATION_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/dpf_regeneration_amber.png`;
export const DRIVER_WARNING_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/driver_warning_default.png`;
export const DRIVER_WARNING_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/driver_warning_amber.png`;
export const DEF_LOW_LEVEL_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/def_low_level_default.png`;
export const DEF_LOW_LEVEL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/def_low_level_amber.png`;
export const MALFUNCTION_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/malfunction_default.png`;
export const MALFUNCTION_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/malfunction_amber.png`;
export const PARKING_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/parking_default.png`;
export const PARKING_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/parking_red.png`;
export const ENGINE_STOP_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_stop_red.png`;
export const ENGINE_STOP_RED_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_stop_default.png`;
export const WATER_IN_FUEL_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/water_in_fuel_default.png`;
export const WATER_IN_FUEL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/water_in_fuel_amber.png`;
export const ENGINE_COOLANT_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_coolant_red.png`;
export const ENGINE_COOLANT_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_coolant_default.png`;
export const SIGNAL_GREEN = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_green.png`;
export const SIGNAL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_amber.png`;
export const SIGNAL_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_red.png`;
export const SPEEDOMETER_SVG = `${cloudFrontURL}/tml-isp-assets/assets/speedometer.png`;
export const EXCEL_SVG = `${cloudFrontURL}/tml-isp-assets/assets/excel_icon.svg`;
export const GROUP_6 = `${cloudFrontURL}/tml-isp-assets/assets/group-6.svg`;
export const BULK_UPLOAD_SVG = `${cloudFrontURL}/tml-isp-assets/assets/bulk_upload.png`;
export const LESS_THAN_ARROW_SVG = `${cloudFrontURL}/tml-isp-assets/assets/less-than-arrow.png`;
export const GREATER_THAN_ARROW_SVG = `${cloudFrontURL}/tml-isp-assets/assets/greater-than-arrow.png`;
export const BLACK_LEFT_CHEVRON_SVG = `${cloudFrontURL}/tml-isp-assets/assets/black-left-chevron.svg`;
export const EXCLAMATION_ICON_SVG = `${cloudFrontURL}/tml-isp-assets/assets/exclamation-icon.svg`;
export const CLOSE_ICON_SVG = `${cloudFrontURL}/tml-isp-assets/assets/close.svg`;




///DIC

export const AADHAR_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/aadhaar.png`;
export const AC_SVG = `${cloudFrontURL}/assets/marketplace/ac.svg`;
export const ACCOUNT_MANAGEMENT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/account-management.svg`;
export const ACTIVE_BRAKING_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/active-braking.png`;
export const ACTIVE_REFUEL_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/active-refuel.png`;
export const ADD_DARK_BLUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/add-dark-blue.svg`;
export const ADVANCED_PLAN_SVG = `${cloudFrontURL}/assets/subscription/advanced_plan.svg`;
export const AIR_FILTER_1_SVG = `${cloudFrontURL}/MobileUploads/TellTales/air-filter-1.svg`;
export const AIR_FILTER_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/air_filter_amber.png`;
export const AIR_FILTER_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/air_filter_default.png`;
export const AIS_SVG = `${cloudFrontURL}/assets/marketplace/ais.svg`;
export const AIS_2_SVG = `${cloudFrontURL}/assets/marketplace/ais2.svg`;
export const ALERT_MOB_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/alert_mob.svg`;
export const ALERT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/alert.svg`;
export const ALERT_2_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/alert_2.svg`;
export const ANALYSE_D_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Analyse-d.svg`;
export const ANALYSE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Analyse.svg`;
export const ANDROID_QR_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/androidQR.png`;
export const API_SVG = `${cloudFrontURL}/assets/marketplace/api.svg`;
export const APPLE_APP_STORE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Apple App Store.svg`;
export const ARCHIVED_CONTRACTS_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/archived_contracts.png`;
export const ARROW_SVG = `${cloudFrontURL}/assets/arrows/Arrow.svg`;
export const ARROW_BUTTON_SVG = `${cloudFrontURL}/assets/arrows/arrow_button.svg`;
export const ARROW_ICON_PNG = `${cloudFrontURL}/assets/arrows/arrowIcon.png`;
export const ARROWS_GREY_PNG = `${cloudFrontURL}/assets/arrows/arrows-grey@3x.png`;
export const ARROWS_PURPLE_PNG = `${cloudFrontURL}/assets/arrows/arrows-purple@3x.png`;
export const ARROWS_YELLOW_PNG = `${cloudFrontURL}/assets/arrows/arrows-yellow.png`;
// export const ASCENDING_GREY_SVG = `${cloudFrontURL}/assets/arrows/ascending-grey.svg`;
// export const ASCENDING_SVG = `${cloudFrontURL}/assets/arrows/ascending.svg`;
export const ASCENDING_GOLD_SVG = `${cloudFrontURL}/assets/arrows/ascending-gold.svg`;
export const LEFT_DRAWER_ARROW = `${cloudFrontURL}/assets/arrows/left-drawer-arrow.svg`;
export const ASHWATI_TRANSPORT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/ashwati-transport.png`;
export const ATTACH_FILE_COPY_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/attach-file-copy.png`;
export const ATM_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/atm-icon-active.svg`;
export const ATM_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/atm-icon.svg`;
export const ATM_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/atm-map-icon.svg`;
export const AVAILABLE_PNG = `${cloudFrontURL}/assets/placeholders/available.png`;
export const AWAY_PIN_ICON_SVG = `${cloudFrontURL}/assets/maps/away-pin-icon.svg`;
export const APPLICATION_DRIVING_SCORE = `${cloudFrontURL}/assets/miscellaneous_icons/application.svg`;
export const ADD_BUTTON_BLUE_BG = `${cloudFrontURL}/assets/miscellaneous_icons/background-plus.svg`;

export const ANALYZE_SELECTED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/analyze-selected.svg`;
export const ANALYZE_1_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/analyze-new.svg`;

export const PLAN_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/plan-icon.svg`;
export const PLAN_SELECTED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/plan-selected.svg`;

export const MILEAGE_SARTHI_HINDI = `${cloudFrontURL}/assets/mileage-saarthi-videos/mileage-saarathi-hindi.mp4`;
export const MILEAGE_SARTHI_ENGLISH = `${cloudFrontURL}/assets/mileage-saarthi-videos/mileage-saarathi-english.mp4`;
export const MILEAGE_SARTHI_PLAY_ICON = `${cloudFrontURL}/assets/mileage-saarthi-videos/play_button.svg`;


export const BACKSVG_PNG = `${cloudFrontURL}/assets/arrows/backsvg.png`;
export const BANK_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bank-icon-active.svg`;
export const BANK_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bank-icon.svg`;
export const BANK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/bank.png`;
export const BANK_SVG = `${cloudFrontURL}/assets/marketplace/bank.svg`;
export const BANK_2_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bank.svg`;
export const BANK_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bank-map-icon.svg`;
export const BANNER_CONNECTING_LOGO_SVG = `${cloudFrontURL}/assets/tata_motors_logos/banner_connecting_logo.svg`;
export const BANNER_LANDING_3X_4122_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/banner-landing@3x.min.jpg`;
export const BANNER_LANDING_3X_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/banner-landing@3x.4122.min.webp`;
export const BANNER_LOGO_WITH_TAG_SVG = `${cloudFrontURL}/assets/tata_motors_logos/banner_logo_with_tag.svg`;
export const BANNER1_MOBILE_VIEW_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Banner1_mobile_view.4122.min.webp`;
export const BANNER1_MOBILE_VIEW_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Banner1_mobile_view.min.jpg`;
export const BANNER_2_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Banner_2.4122.min.webp`;
export const BANNER_2_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Banner_2.min.jpg`;
export const BANNER2_MOBILE_VIEW_4122_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Banner2_mobile-view.min.jpg`;
export const BANNER2_MOBILE_VIEW_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Banner2_mobile-view.4122.min.webp`;
export const BANNER3_MOBILE_VIEW_4122_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Banner3_mobile_view.min.jpg`;
export const BANNER3_MOBILE_VIEW_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Banner3_mobile_view.4122.min.webp`;
export const BANNER_3_MIN_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Banner_3.min.4122.min.webp`;
export const BANNER_3_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Banner_3.min.jpg`;
export const BANNER_VERTICAL_LINE = `${cloudFrontURL}/assets/subscription/banner_vertical_line.svg`;
export const BILLED_CUST_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/billed-cust.svg`;
export const BLACK_CLUSTER_PNG = `${cloudFrontURL}/assets/maps/black-cluster.png`;
export const BLACK_DOT_PNG = `${cloudFrontURL}/assets/maps/black-dot.png`;
export const BLUE_CHEVRON_RIGHT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/blue-chevron-right.svg`;
export const BLUE_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/blue_cluster.svg`;
export const BLUE_PIN_PNG = `${cloudFrontURL}/assets/maps/blue-pin.png`;
export const BOOKED_PNG = `${cloudFrontURL}/assets/placeholders/booked.png`;
export const BOOKING_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/booking.png`;
export const BOX_DARK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/box-dark.png`;
export const BOX_LIGHT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/box-light.png`;
export const BPCL_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bpcl-icon.svg`;
export const BPCL_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/bpcl.svg`;
export const BRAKE_2_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/brake2.svg`;
export const BRANCH_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/branch-active.svg`;
export const BRANCH_SVG = `${cloudFrontURL}/assets/maps/branch.svg`;
export const BRANCH_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/branch-tab.svg`;
export const BRIEFCASE_PNG = `${cloudFrontURL}/assets/general_images/briefcase.png`;
export const BROWSE1_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Browse 1.svg`;
export const BULB_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/bulb.svg`;
export const BULKUPLOAD_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/bulk_upload.png`;
export const BUS_ADDED_SUCCESS_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/BusAddedSuccess.png`;
export const BUS_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Bus.png`;
export const VEHICLE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/vehicle.svg`;
export const BUS_LOCATION_BLUE_SVG = `${cloudFrontURL}/assets/maps/bus-location-blue.svg`;
export const BUZZER_ERROR_ICON_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/buzzer_error_icon_default.png`;
export const BOOKMARK_ICON = `${cloudFrontURL}/assets/general_images/bookmark.svg`;
export const BOOKMARK_HOLLOW = `${cloudFrontURL}/assets/general_images/bookmark-hollow.svg`;

export const CALENDER_DARK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/calender-dark.png`;
// export const CALENDAR_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/calendar-icon.svg`;
export const CALENDAR_ICON_2_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/calendar-icon-2.svg`;
export const CALENDAR_ICON_3_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/calendar-icon-3.svg`;
export const CALENDER_LIGHT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/calender-light.png`;
export const CALENDAR_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/calendar.png`;
export const CALENDAR_FE_SVG = `${cloudFrontURL}/assets/general_images/calendar_date-range.svg`;
export const CALL_SVG = `${cloudFrontURL}/assets/social_logos/Call.svg`;
export const CALLERICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/callerIcon.svg`;
export const CAMERA_ERROR_ICON_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/camera_error_icon_default.png`;
export const CANCEL_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/cancel.png`;
export const CANCEL_2_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/cancel-2.png`;
export const CAR_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/car.svg`;
export const CAR_BLUE_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/car-blue.png`;
export const CAR_PINK_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/car-pink.png`;
export const CAR_RED_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/car-red.svg`;
export const CAR_YELLOW_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/car-yellow.svg`;
export const CARBON_FOOTPRINT_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/carbon-footprint-grey-circle.svg`;
export const CARGO_SVG = `${cloudFrontURL}/assets/marketplace/cargo.svg`;
export const CARD_FORM_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/card-form.svg`;
export const CARD_TOGGLE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/card-toggle.svg`;
export const CASH_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/cash-blue.png`;
export const CASH_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/cash-grey-circle.svg`;
export const CHAIR_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/chair-blue.png`;
export const CHAIR_BLUEE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/chair-bluee.png`;
export const CHARGING_FAULT_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/charging_fault.png`;
export const CHARGING_HISTORY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/charging-history.svg`;
export const CHARGING_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/charging-icon-active.svg`;
export const CHARGING_ICON_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/charging_fault_default.png`;
export const CHARGING_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/charging-icon.svg`;
export const CHAT_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/chat-icon.svg`;
export const CHECK_MARK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/check-mark.png`;
export const CHECK_ENG_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/check_eng.svg`;
export const CHECKED_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/checked.png`;
export const CHECKENGINE_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/checkengine.svg`;
export const CHEVRON_DOWN_SVG = `${cloudFrontURL}/assets/arrows/chevron_down.svg`;
export const CHEVRON_UP_SVG = `${cloudFrontURL}/assets/arrows/chevron_up.svg`;
export const CHEVRON_LEFT_SVG = `${cloudFrontURL}/assets/arrows/chevron-left.svg`;
export const CLOCK_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/clockIcon.svg`;
export const CLOCK_ICON_2_SVG = `${cloudFrontURL}/assets/general_images/clock_icon.svg`;
export const CLOCK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/clock.png`;
export const CLOSE_MULTIPLE_INVOICES_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/close-multiple-invoices.svg`;
export const CLOSE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/close.svg`;
export const CNG_FUEL_STATION_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/cng_fuel_station_icon.svg`;
export const CNG_PROGRESS_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/cng-progress-icon.svg`;
export const CNG_STATION_GREEN_PNG = `${cloudFrontURL}/assets/maps/cng_station_green.png`;
export const CNG_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/cng-icon.svg`;
export const CNG_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/cng.svg`;
export const CNG_TAB_ACTIVE_ICON_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/cng-tab-active-icon.svg`;
export const CNG_TAB_ICON_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/cng-tab-icon.svg`;
export const CNG_VEHICLE_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/cng_vehicle.png`;
export const COASTING_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/coasting.svg`;
export const COOLANT_LOW_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/coolant_low_default.png`;
export const COOLANT_LOW_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/coolant_low.png`;
export const CONSIGNOR_EMPTY_SVG = `${cloudFrontURL}/assets/placeholders/consignor-empty.svg`;
export const COMBINED_SHAPE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/combined-shape.png`;
export const COMPANY_PLACEHOLDER_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/company-placeholder.png`;
export const COMPLIANCE_PNG = `${cloudFrontURL}/assets/placeholders/compliance.png`;
export const COMPLIANCE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/complaince.svg`;
export const COPY_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/copy.png`;
export const COLLAPSE_FULLSCREEN_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/collapse-fullscreen.svg`;
export const COMBINED_SHAPE_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/combined-shape@3x.png`;
export const CONNECTED_DIAGNOSTICS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/connected_diagnostics.svg`;
export const CONNECTED_DIAGNOSTICS_MOB_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/connected_diagnostics_mob.svg`;
export const CRITICAL_HEALTH_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/critical-health.svg`;
export const COPY_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/copy-icon.svg`;
export const CROSS_RED_BUTTON_SVG = `${cloudFrontURL}/assets/general_images/red_crose_icon.svg`;
export const CREATE_TRIP_MAP_SVG = `${cloudFrontURL}/assets/maps/create-trip-map.svg`;

export const DUSTBIN = `${cloudFrontURL}/assets/miscellaneous_icons/dustbin.svg`;
export const DATETIME_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/datetime.svg`;
export const DEALER_IMG_ACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/dealer_img_active.svg`;
export const DEALER_IMG_INACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/dealer_img_inactive.svg`;
export const DEALERS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Dealers.svg`;
export const DEEP_BLUE_DOWNLOAD = `${cloudFrontURL}/assets/marketplace/deep-blue-download.svg`;
export const DEEP_DRIVING_INSIGHTS_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/deep-driving-insights.svg`;
export const DEF_LEVEL_GREY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/def-level-grey.svg`;
export const DEF_LEVEL_LOW_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/DefLevelLow.svg`;
export const DEF_LOW_LEVEL_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/def_low_level_amber.png`;
export const DEF_LOW_LEVEL_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/def_low_level_default.png`;
export const DEF_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/def.png`;
export const DEF_PROGRESS_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/def-progress-icon.svg`;
export const DEF_STATION_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/def-station-active.svg`;
export const DEF_STATION_ICON_INACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/def-station-icon-inactive.svg`;
export const DEFAULT_FLEETEDGE_LOGO_PNG = `${cloudFrontURL}/pplBasedImages/default_fleetedge_logo.png`;
export const DEFAULT_IMAGE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/defaultImage.png`;
export const DEFAULT_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/default-truck.svg`;
export const DELETE_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/delete@3x.png`;
export const DELETE_BTN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/deleteBtn.png`;
export const DELETE_ICON_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/delete-icon.png`;
export const DELETE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/delete.svg`;
export const DELETE_WARNING_SVG = `${cloudFrontURL}/assets/tyre-management/warning-red-icon.svg`;
export const DELINK_ICON_SVG = `${cloudFrontURL}/assets/marketplace/delink-icon.svg`;
// export const DESCENDING_GREY_SVG = `${cloudFrontURL}/assets/arrows/descending-grey.svg`;
// export const DESCENDING_SVG = `${cloudFrontURL}/assets/arrows/descending.svg`;
export const DESCENDING_GOLD_SVG = `${cloudFrontURL}/assets/arrows/descending-gold.svg`;
export const DESTINATION_MARKER_RED_PNG = `${cloudFrontURL}/assets/maps/destination_marker_red.png`;
export const DESTINATION_ARROWS_SVG = `${cloudFrontURL}/assets/arrows/destinationarros.svg`;
export const DESTINATION_ARROWS_GRAY_SVG = `${cloudFrontURL}/assets/general_images/destination.svg`;
export const DPF_REGENERATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/dpf_regeneration_default.png`;
export const DHABA_AND_RESTAURANT = `${cloudFrontURL}/assets/vehicle/general_icons/dhaba&restaurant.svg`;
export const DHABA_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/dhaba-icon-active.svg`;
export const DHABA_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/dhaba-icon.svg`;
export const DHABA_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/dhaba-map-icon.svg`;
export const DIESEL_VEHICLE_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/diesel-vehicle.png`;
export const DISTANCE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/distance.svg`;
export const DIY_CONSULTING_SELECTED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/DIY-Consulting-selected.svg`;
export const DIY_CONSULTING_DEFAULT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/DIY-Consulting-default.svg`;
export const DOC_PNG = `${cloudFrontURL}/assets/placeholders/doc.png`;
export const DOT_IMAGE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/dotImage.png`;
export const DOWN_ARROW_2_PNG = `${cloudFrontURL}/assets/arrows/downArrow (2).png`;
export const DOWNARROW_PNG = `${cloudFrontURL}/assets/arrows/DownArrow.png`;
export const DOWN_ICON_SVG = `${cloudFrontURL}/assets/arrows/down-icon.svg`;
export const DOWN_PNG = `${cloudFrontURL}/assets/arrows/down.png`;
export const DOWN_TWO_PNG = `${cloudFrontURL}/assets/arrows/down-two.png`;
export const DOWN_WHITE_PNG = `${cloudFrontURL}/assets/arrows/downWhite.png`;
export const DOWNLOAD_EXCEL_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/download_excel.png`;
export const DOWNLOAD_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/download-icon.svg`;
export const DOWNLOAD_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/download.svg`;
export const DPF_REGENERATION_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/dpf_regeneration_amber.png`;
export const DRAG_AND_DROP = `${cloudFrontURL}/assets/miscellaneous_icons/drag-n-drop.svg`;
export const DRIVER_APP_PLAN_1_SVG = `${cloudFrontURL}/assets/avatars/driver-app-plan1.svg`;
export const DRIVER_APPLICATION_SVG = `${cloudFrontURL}/assets/avatars/driver-application.svg`;
export const DRIVER_BEHAVIOUR_DATA_SVG = `${cloudFrontURL}/assets/avatars/driver-behaviour-data.svg`;
export const DRIVER_BEHAVIOUR_PLAN_1_SVG = `${cloudFrontURL}/assets/avatars/driver-behaviour-plan1.svg`;
export const FUEL_EFFICIENCY = `${cloudFrontURL}/assets/subscription/fuel_efficiency.png`;
export const GEAR_UTILIZATION = `${cloudFrontURL}/assets/subscription/gear_utilization.png`;
export const VEHICLE_ALERTS = `${cloudFrontURL}/assets/subscription/vehicle_alerts.png`;
export const DRIVER_SVG = `${cloudFrontURL}/assets/avatars/driver.svg`;
export const DRIVER_EMPTY_SVG = `${cloudFrontURL}/assets/avatars/driver-empty.svg`;
export const DRIVER_MANAGEMENT_SVG = `${cloudFrontURL}/assets/avatars/driver-management.svg`;
export const DRIVER_PLACEHOLDER_PNG = `${cloudFrontURL}/assets/general_images/driver-placeholder.png`;
export const DRIVER_SHAPE_SVG = `${cloudFrontURL}/assets/avatars/driver-shape.svg`;
export const DRIVER_SKILL_PNG = `${cloudFrontURL}/assets/avatars/driver_skill.png`;
export const DRIVER_WARNING_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/driver_warning_amber.png`;
export const DRIVER_WARNING_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/driver_warning_default.png`;
export const DRIVERS_PNG = `${cloudFrontURL}/assets/avatars/Drivers.png`;
export const DRIVING_BEHAVIOUR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/driving_behaviour.svg`;
export const DRIVING_SCORE_PLAN_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/driving-score-plan1.svg`;
export const DROP_SVG = `${cloudFrontURL}/assets/vehicle/fuel_types/drop.svg`;
export const DOWNLOAD_ARROW_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/download.svg`;
export const DROPDOWN_ARROW_BLUE_SVG = `${cloudFrontURL}/assets/general_images/dropdown_arrow_blue.svg`;

export const EXPAND_MAP_ICON = `${cloudFrontURL}/assets/miscellaneous_icons/expand.svg`;
export const EARTH_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/earth-blue.png`;
export const EARTH_PINK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/earth-pink.png`;
export const ECU_SVG = `${cloudFrontURL}/assets/marketplace/ecu.svg`;
export const EDIT_SVG = `${cloudFrontURL}/assets/marketplace/edit.svg`;
export const EDIT_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/edit_icon.svg`;
export const EDIT_ICON_PENCIL_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/edit-pencil.svg`;
export const EDIT_PREFERENCE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/edit-preference-icon.svg`;
export const EDIT_WITH_LINE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/edit_with_line.png`;
export const EFFICIENCY_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/efficiency.png`;
export const ELECTRIC_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/electric.png`;
export const ELECTRIC_VEHICLE_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/electric_vehicle.png`;
export const ELECTRIC_VEHICLE_V1_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/electric_vehicle_v1.png`;
export const EMAIL_SVG = `${cloudFrontURL}/assets/social_logos/Email.svg`;
export const EMAIL_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/email-icon.svg`;
export const EMPTY_CHECK_BOX_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/empty-check-box.png`;
export const ENGINE_OIL_LOW_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/EngineOilLow.svg`;
export const ENGINE_SVG = `${cloudFrontURL}/assets/marketplace/engine.svg`;
export const ENGINETEMP_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/enginetemp.svg`;
export const ENGINE_COOLANT_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/engine_coolant_default.png`;
export const ENGINE_COOLANT_RED_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/engine_coolant_red.png`;
export const ENGINE_LAMP_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/engine_lamp_amber.png`;
export const ENGINE_LAMP_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/engine_lamp_default.png`;
export const ENGINE_RUN_HOUR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/engine-run-hour.svg`;
export const ENGINE_MODE_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/engine-mode.svg`;
export const ENGINE_MODES_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/engine-modes.svg`;
export const ENGINE_STOP_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/engine_stop_default.png`;
export const ENGINE_STOP_RED_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/engine_stop_red.png`;
export const ERROR_SVG = `${cloudFrontURL}/assets/marketplace/error.svg`;
export const EV_CHARGING_STN_GREEN_PNG = `${cloudFrontURL}/assets/maps/ev_charging_stn_green.png`;
export const EV_FAULT_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/ev_fault_icon.svg`;
export const EV_FAULT_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/ev_fault_indication_default.png`;
export const EV_FAULT_INDICATION_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/ev_fault_indication.png`;
export const EV_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/ev-icon.svg`;
export const EV_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/ev.svg`;
export const EXCLAMATORY_ICON_SVG = `${cloudFrontURL}/assets/general_images/Exclamatory_Icon.svg`;
export const EXPORT_EXCEL_TRACK = `${cloudFrontURL}/assets/miscellaneous_icons/export-excel-track.svg`;
export const EXPORT_MAIL_TRACK = `${cloudFrontURL}/assets/miscellaneous_icons/export-mail-track.svg`;
export const EXPORT_PDF_TRACK = `${cloudFrontURL}/assets/miscellaneous_icons/export-pdf-track.svg`;
export const EYE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/eye.png`;
export const EYE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/eye.svg`;
export const EXCEL_ICON = `${cloudFrontURL}/assets/general_images/Excel_download_img.svg`;
export const EXPIRING_SOON_ICON_SVG = `${cloudFrontURL}/assets/subscription/expiring_soon.svg`;

export const FACEBOOK_SVG = `${cloudFrontURL}/assets/social_logos/Facebook.svg`;
export const FAILED_SVG = `${cloudFrontURL}/assets/subscription/Failed.svg`;
export const FASTTAG_SVG = `${cloudFrontURL}/assets/marketplace/fastag.svg`;
export const FASTTAGLINK_PNG = `${cloudFrontURL}/assets/marketplace/fastaglink.png`;
export const FEATURE_ANALYSE_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_analyse.svg`;
export const FEATURE_ICON_ENGINE_MODES_1_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/Feature-icon-Engine-modes 1.svg`;
export const FEATURE_ICON_LIVE_TRACKING_1_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/Feature-icon-Live-Tracking 1.svg`;
export const FEATURE_ICON_TRIP_PLANNING_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/Feature-icon-Trip-planning.svg`;
export const FEATURE_INSIGHTS_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_insights.svg`;
export const FEATURE_PLAN_FEATURE_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_plan-feature.svg`;
export const FEATURE_TRACK_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_track.svg`;
export const FEATURE_VEHICLE_TRACING_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_vehicle_tracing.svg`;
export const FEATURES_INCIDENTS_ALERTS_SVG = `${cloudFrontURL}/assets/FeatureIcons/feature_incidents_alerts.svg`;
export const FE_LIGHT_MODE_GROWTH_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fe-light-mode-growth.svg`;
export const FIL_SVG = `${cloudFrontURL}/assets/general_images/Fil.png`;
export const FILTER_SVG = `${cloudFrontURL}/assets/maps/filter.svg`;
export const FILTER_2_SVG = `${cloudFrontURL}/assets/general_images/filter.svg`;
export const FILTERICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/filtericon.svg`;
export const FLEET_LOCATION_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/fleet-location.svg`;
export const FLEETWISE_PLAN_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/fleetwise-plan1.svg`;
export const FLEETWISE_VEHICLEWISE_INSIGHTS_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/fleetwise-vehiclewise-insights.svg`;
export const FUEL_CONSUME_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuelConsume.svg`;
export const FUEL_CONSUMED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-consumed.svg`;
export const FUEL_CONSUMED_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-consumed-grey-circle.svg`;
export const FUEL_CONSUMPTION_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuel_consumption.svg`;
export const FUEL_CONSUMED_GREY_CIRCLE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-consumed-grey-circle.svg`;
export const FUEL_DISPENSER_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuelDispenser.svg`;
export const FUEL_ECONOMY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/fuel-economy.svg`;
export const FUEL_ECONOMY_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-economy-grey-circle.svg`;
export const FUEL_ICON_BLUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuelIconBlue.svg`;
export const FUEL_PUMP = `${cloudFrontURL}/assets/general_images/fuel-station-trace.svg`;
export const FUEL_PROGRESS_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-progress-icon.svg`;
export const FUEL_REFIL_PNG = `${cloudFrontURL}/assets/subscription/Subscription_Mobile_Images/Fuel_refill.png`;
export const FUEL_REFILL_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/fuel-refill.svg`;
export const FUEL_REFILL_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/fuel-refil-plan1.svg`;
export const FUEL_STATION_ACTIVE_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/fuel-station-active-tab.svg`;
export const FUEL_STATION_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/fuel-station-tab.svg`;
export const FUEL_STATION_SVG = `${cloudFrontURL}/assets/maps/fuel-station.svg`;
export const FUEL_STATION_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/013-fuel-station@3x.png`;
export const FUEL_STATION_ICON_PNG = `${cloudFrontURL}/assets/maps/fuel-station-icon.png`;
export const FUEL_THEFT_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/fuel-theft.png`;
export const FUEL_TYPE_CNG_SVG = `${cloudFrontURL}/assets/vehicle/fuel_types/fuelt_type_cng.svg`;
export const FUEL_TYPE_ELECTRIC_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/feult_type_electric.svg`;
export const FUEL_YELLOW_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/fuel-yellow.png`;
export const FUELPUMP_IMG_ACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuelpump_img_active.svg`;
export const FUELPUMP_IMG_INACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/fuelpump_img_inactive.svg`;
export const FILTERS_ICON = `${cloudFrontURL}/assets/general_images/Filters.svg`;

export const GAS_SVG = `${cloudFrontURL}/assets/marketplace/gas.svg`;
export const GEAR_UTILIZATION_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/gear-utilization-grey-circle.svg`;
export const GEAR_UTILIZATION_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/gear-utlization-plan1.svg`;
export const GEAR_UTILIZATION_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/gear-utilization.svg`;
export const GEO_FENCING_SVG = `${cloudFrontURL}/assets/maps/geo-fencing.svg`;
export const GEO_FENCING_2_SVG = `${cloudFrontURL}/assets/maps/geo-fencing.svg`;
export const GEOFENCE_IN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/geofence-in.png`;
export const GEOFENCE_OUT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/geofence-out.png`;
export const GEOFENCE_PNG = `${cloudFrontURL}/assets/maps/geofence.png`;
export const GEOFENCE_2_SVG = `${cloudFrontURL}/assets/maps/geofence-2.svg`;
export const GEOFENCE_REPORT_SVG = `${cloudFrontURL}/assets/analyse/geofence_report.svg`;
export const GATEWAY_DISTRIPARKS_LIMITED = `${cloudFrontURL}/assets/general_images/gateway-distripark-limited.svg`;
export const GLOBAL_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/global.png`;
export const GOLD_PLAN_SVG = `${cloudFrontURL}/assets/subscription/gold_plan.svg`;
export const GOOD_HEALTH_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/good-health.svg`;
export const GOOGLE_PLAY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Google Play.svg`;
export const GOOGLE_MAPS_ICON_SVG = `${cloudFrontURL}/assets/maps/google_maps_icon.svg`;
export const GREATER_THAN_ARROW_PNG = `${cloudFrontURL}/assets/arrows/greater-than-arrow.png`;
export const GREEN_ARROW_SVG = `${cloudFrontURL}/assets/arrows/green_arrow.svg`;
export const GREEN_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/green_cluster.svg`;
export const GREEN_DOT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/greenDot.png`;
export const GREEN_DOT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/green_dot.svg`;
export const GREEN_LOC_ICON_PNG = `${cloudFrontURL}/assets/maps/green_loc_icon.png`;
export const GREEN_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Green_Truck.svg`;
export const GREEN_TRUCK_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Green_Truck_1.svg`;
export const GREEN_ZIG_ZAG_ARROW_SVG = `${cloudFrontURL}/assets/arrows/green-zig-zag-arrow.svg`;
export const GRID_CLICK_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/grid-click-icon.svg`;
export const GRID_NOT_CLICK_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/grid-not-click-icon.svg`;
export const GRID_ICON_SELECTED = `${cloudFrontURL}/assets/general_images/grid-icon-selected.svg`;
export const GREY_DOT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/grey_dot.svg`;
export const GREY_PIN_PNG = `${cloudFrontURL}/assets/maps/grey-pin.png`;
export const GREY_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/grey_truck.svg`;
export const GREY_TRUCK_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/grey_truck_1.svg`;  /// Verison 12
export const GROUP_PNG = `${cloudFrontURL}/assets/maps/group.png`;
export const GROUP_3_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/group-3.svg`;
export const GROUP_3X_PNG = `${cloudFrontURL}/assets/maps/group@3x.png`;
export const GROUP_4_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/group-4.svg`;
export const GROUP_5_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-5@3x.png`;
export const GROUP_5_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-5.png`;
export const GROUP_COPY_5_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-copy-5.png`;
export const GROUP_6_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/group-6.svg`;
export const GROUP_9_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/group-9.svg`;
export const GROUP_13_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-13.png`;
export const GROUP_13_2X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-13@2x.png`;
export const GROUP_13_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-13@3x.png`;
export const GROUP_19_COPY_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/group-19-copy.png`;
export const GREY_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/grey_cluster.svg`;
export const GRAPH_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/graph.svg`;
export const GRAPH_ICON_SVG = `${cloudFrontURL}/assets/general_images/Graph_svg.svg`;
export const GRAPH_BACKGROUND_ICON_SVG = `${cloudFrontURL}/assets/general_images/Graph_background_svg.svg`;

export const HARSH_ACCELARATION_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/Harsh_Accelaration.svg`;
export const HARSH_ACCELARATION_ALERT_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/Harsh_Accelaration_Alert_Cluster.svg`;
export const HARSH_BRAKE_ALERT_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/Harsh_Brake_Alert_Cluster.svg`;
export const HARSH_BREKING_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/Harsh_Breking.svg`;
export const HARSH_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/harsh.svg`;
export const HA_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/ha.svg`;
export const HB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hb.svg`;
export const HARSH_BREAKING_GREY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/harsh-breaking-grey.svg`;
export const HEALTH_INDI_PLAN_1_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/health-indi-plan1.svg`;
export const HELP_AND_SUPPORT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/help&support.svg`;
export const HELP_DESK = `${cloudFrontURL}/assets/marketplace/help-desk.svg`;
export const HIGH_TEMPERATURE_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/high_temperature_indication_default.png`;
export const HIGH_TEMPERATURE_INDICATION_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/high_temperature_indication.png`;
export const HISTORIC_DATA_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/historic-data-plan1.svg`;
export const HISTORIC_DATA_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/historic_data.svg`;
export const HORIZONTAL_LINE_PNG = `${cloudFrontURL}/assets/subscription/horizontal-line.png`;
export const HOSPITAL_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hospital-map-icon.svg`;
export const HOSPITAL_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hospital-icon-active.svg`;
export const HOSPITAL_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hospital-icon.svg`;
export const HOSPITAL_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hospital.svg`;
export const HPCL_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/hpcl.svg`;
export const HV_CHARGE_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/hv_charge_indication_default.png`;
export const HV_CHARGE_INDICATION_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/hv_charge_indication.png`;

export const ICICI_ICON_PNG = `${cloudFrontURL}/assets/marketplace/icici-icon.png`;
export const ICON_BUSINESS_FORECAST_CARDS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Icon - Business Forecast Cards.svg`;
export const ICON_TRIP_EXPENSES_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/icon-trip-expenses@3x.png`;
export const ICONS_8_EDIT_USER_32_PNG = `${cloudFrontURL}/assets/avatars/icons8-edit-user-32.png`;
export const IDLE_STATE_PNG = `${cloudFrontURL}/assets/maps/idle_state.png`;
export const IDLING_ALERT_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/Idling_Alert_Cluster.svg`;
export const IDFCICON_PNG = `${cloudFrontURL}/assets/marketplace/idfcicon.png`;
export const IDLE_FUEL_LOSS_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/idleFuelLoss.svg`;
export const IDLE_TIME_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/idleTime.svg`;
export const IDLING_BEAM_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/idling_beam_icon.svg`;
export const IDLING_IOCN3_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Idling_Iocn3.png`;
export const IDLING_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/idling.svg`;
export const IDLING_VS_FUEL_LOSS_SVG = `${cloudFrontURL}/assets/analyse/idling_vs_fuel_loss.svg`;
export const IDLING_VEHICLE_GREY_CIRCLE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/idling-vehicle-grey-circle.svg`;
export const IMAGE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/image.png`;
export const IMAGE_2_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Image_2.4122.min.webp`;
export const IMAGE_2_MIN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Image_2.min.png`;
export const IMAGE_4_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Image-4.4122.min.webp`;
export const IMAGE_4_4122_MIN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Image-4.min.png`;
export const IMAGE_INFO_PNG = `${cloudFrontURL}/assets/subscription/Subscription_Mobile_Images/info_icon.png`;
export const INCIDENTS_ALERTS_MIN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Incidents_Alerts.min.png`;
export const INCIDENTS_ALERTS_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Incidents_Alerts.4122.min.webp`;
export const INCIDENTS_MOB_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/incidents_mob.svg`;
export const INCIDENTS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/incidents.svg`;
export const INFO_SVG = `${cloudFrontURL}/assets/marketplace/info.svg`;
export const INFO_2_SVG = `${cloudFrontURL}/assets/marketplace/info-2.svg`;
export const INFO_PNG = `${cloudFrontURL}/assets/placeholders/info.png`;
export const WHITE_INFO_SVG = `${cloudFrontURL}/assets/marketplace/white-info-svg.svg`;
export const INFOGRAPHIC_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/infographic.4122.min.webp`;
export const INFOGRAPHIC_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/infographic.png`;
export const INFORMATION_ICON_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/information-icon.png`;
export const INGEAR_COASTING_GREY_CIRCLE = `${cloudFrontURL}/assets/vehicle/general_icons/ingear-coasting-grey-circle.svg`;
export const INHIBITOR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/inhibitor-percent-1.svg`;
export const INSTAGRAM_SVG = `${cloudFrontURL}/assets/social_logos/Instagram.svg`;
export const INVOICE_EYE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/invoice-eye.svg`;
export const IOCL_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/iocl-icon.svg`;
export const IOCL_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/iocl.svg`;
export const IR_ERROR_ICON_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/ir_error_icon_default.png`;


export const KALINGA_COMMERCIAL_CORPORATION = `${cloudFrontURL}/assets/general_images/kalinga-commercial-corporation.svg`;

export const LOGOUT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/logout.svg`;
export const LADIES_PNG = `${cloudFrontURL}/assets/placeholders/ladies.png`;
export const LANGUAGE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/language-icon.svg`;
export const LEARN_VIDEO_ICON_HOME_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Learn_video_icon_home.svg`;
export const LEFT_ICON_SVG = `${cloudFrontURL}/assets/arrows/left-icon.svg`;
export const LEFT_SWIPER_SVG = `${cloudFrontURL}/assets/arrows/left-swipper-arrow.svg`;
export const LESS_THAN_ARROW_PNG = `${cloudFrontURL}/assets/arrows/less-than-arrow.png`;
export const LINE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/line.svg`;
export const LINKEDIN_SVG = `${cloudFrontURL}/assets/social_logos/LinkedIn.svg`;
export const LINKED_VEHICLE_ICON_SVG = `${cloudFrontURL}/assets/subscription/linked_vehicle_icon.svg`;
export const LIVE_CRITICAL_ALERTS_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/live-critical-alerts.svg`;
export const LIVE_CRITICAL_PLAN_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/live-critical-plan1.svg`;
export const LIVE_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/live-plan1.svg`;
export const LIST_ICON_SVG = `${cloudFrontURL}/assets/general_images/list-icon-svg.svg`;
export const LIST_ICON_SELECTED_SVG = `${cloudFrontURL}/assets/general_images/list-icon-selected-svg.svg`;
export const LOADING_AREA_ACTIVE_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/loading-area-active-tab.svg`;
export const LOADING_AREA_SVG = `${cloudFrontURL}/assets/maps/loading-area.svg`;
export const LOADING_AREA_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/loading-area-tab.svg`;
export const LOCATION_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/location.png`;
export const LOCATION_2_SVG = `${cloudFrontURL}/assets/maps/location (2).svg`;
export const LOCATION_REFRESH_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/location-refresh.png`;
export const LOCATION_WITH_CIRCLES_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/location-with-circles.png`;
export const LOCK_ICON = `${cloudFrontURL}/assets/miscellaneous_icons/lock-icon.svg`;
export const LOCATION_ICON_SVG = `${cloudFrontURL}/assets/general_images/location_icon.svg`;
export const LOW_FUEL_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/low_fuel_indication_default.png`;
export const LOW_FUEL_INDICATION_PNG = `${cloudFrontURL}/MobileUploads/TellTales/low_fuel_indication_default.png`;
export const LOW_PRESSURE_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/low_pressure_indication_default.png`;
export const LOW_RPM_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/low-rpm-grey-circle.svg`;
export const LOW_SOC_WARNING_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/low_soc_warning_default.png`;
export const LOW_SOC_WARNING_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/low_soc_warning.png`;
export const LV_BATTERY_NOT_CHARGING_IND_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/lv_battery_not_charging_ind_default.png`;
export const LV_BATTERY_NOT_CHARGING_PNG = `${cloudFrontURL}/MobileUploads/TellTales/lv_battery_not_charging_ind.png`;

export const MAIL_BLUE_SVG = `${cloudFrontURL}/assets/marketplace/mail-blue.svg`;
export const MAIL_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/mail-icon.svg`;
export const MAINTENANCE_SCREEN_PNG = `${cloudFrontURL}/assets/subscription/Maintainence-screen.png`;
export const MALFUNCTION_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/malfunction_amber.png`;
export const MALFUNCTION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/malfunction_default.png`;
export const MAPS_AND_FLAGS_PNG = `${cloudFrontURL}/assets/maps/maps-and-flags.png`;
export const MAP_CALENDAR = `${cloudFrontURL}/assets/general_images/map-calendar-icon.svg`;
export const MAP_FILTER = `${cloudFrontURL}/assets/general_images/map-filter-icon.svg`;
export const MAP_DURATION = `${cloudFrontURL}/assets/general_images/duration-icon.svg`;
export const MARK_AS_CLOSED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/mark-as-closed.svg`;
export const MARKER_LOCATION_VEHICLE_SVG = `${cloudFrontURL}/assets/maps/marker-location-vehicle.svg`;
export const MARKETPLACE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/marketplace.svg`;
export const MARKETPLACE_D_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/marketplace-d.svg`;
export const MECHANIC_SVG = `${cloudFrontURL}/assets/maps/mechanic.svg`;
export const MECHANICS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Mechanics.svg`;
export const MEDICAL_STORE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/medical-store.svg`;
export const MONTHLY_REPORT_SVG = `${cloudFrontURL}/assets/analyse/monthly_report.svg`;
export const MOVE_STATE_PNG = `${cloudFrontURL}/assets/maps/move_state.png`;
export const MOVING_BEAM_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/moving_beam_icon.svg`;
export const MOBILE_TMIBASL_BANNER = `${cloudFrontURL}/assets/general_images/Mobile-Tmibasl-Banner.png`;
export const VIDEOGRAPHER_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/multimedia.svg`;

export const NATURE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/nature.png`;
export const NEUTRAL_RUNNING_GREY_CIRCLE = `${cloudFrontURL}/assets/vehicle/general_icons/neutral-running-grey-circle.svg`;
export const NIGHT_DRIVE_PLAN_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/night-drive-plan1.svg`;
export const NIGHT_DRIVING_TIME_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Night_Driving_time.svg`;
export const NIGHT_DRIVING_GREY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/night-driving-grey.svg`;
export const NIGHT_TIME_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/nightTime.svg`;
export const NIGHT_TRACKING_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/night-tracking.svg`;
export const NET_VALUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/net_value.svg`;
export const NEW_TATA_VEHICLE_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/new-tata-vehicle.png`;
export const NOTIFICATION_EMPTY_SVG = `${cloudFrontURL}/assets/Notification/notification-empty.svg`;
export const NOTIFICATION_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/notify.svg`;
export const NOTIFICATION_SETTINGS_PNG = `${cloudFrontURL}/assets/Notification/notification_settings.png`;
export const NOUN_CALL_2539715_COPY_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/noun-call-2539715-copy@3x.png`;
export const NOUN_MAIL_2035124_COPY_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/noun-mail-2035124-copy@3x.png`;
export const NON_TML_NO_RECORDS_SVG = `${cloudFrontURL}/assets/general_images/non-tml-no-records-found.svg`;


export const OBD_MALFUNCTION_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/obd-malfunction.svg`;
export const OCCUPANCY_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Occupancy.png`;
export const ODOMETER_CIRCLE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/odometer-circle.svg`;
export const ODOMETER_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/odometer-grey-circle.svg`;
export const ODOMETER_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/odometer.svg`;
export const ODOMETER_2_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/odometer-2.svg`;
export const ODOMETER_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/odometer_icon.svg`;
export const OFF_TRIP_ORANGE_CLUSTER_PNG = `${cloudFrontURL}/assets/maps/off-trip-orange-cluster.png`;
export const OFFBOARD_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/offboard.png`;
export const OFFERINGS_SELECTED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Offerings-default.svg`;
export const OFFERINGS_DEFAULT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Offerings-selected.svg`;
export const OFFLINE_BEAM_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/offline_beam_icon.svg`;
export const OFFLINE_STATE_PNG = `${cloudFrontURL}/assets/maps/offline_state.png`;
export const ON_TIME_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/On-time.png`;
export const OK_ICON_SVG = `${cloudFrontURL}/assets/general_images/OK_Icon.svg`;
export const ONBOARDING_BANNER_SVG = `${cloudFrontURL}/assets/subscription/onboarding-banner.svg`;
export const ONBOARDING_INVOICES_SVG = `${cloudFrontURL}/assets/subscription/onboarding-invoices.svg`;
export const ONBOARDING_TRUCK_SVG = `${cloudFrontURL}/assets/subscription/onboarding-truck.svg`;
export const OPEN_INVOICES_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/open-invoices.svg`;
export const OPERATING_COST_EMPTY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/operating-cost-empty.svg`;
export const OPPOSITE_IDLING_VEHICLE_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/opposite_idling_vehicle.svg`;
export const OVERSPEEDING_HISTORY_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/overspeeding_icon.svg`;

export const OPPOSITE_MOVING_VEHICLE_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/opposite_moving_vehicle.svg`;
export const OPPOSITE_OFFLINE_VEHICLE_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/opposite_offline_vehicle.svg`;
export const OPPOSITE_STOPPED_VEHICLE_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/opposite_stopped_vehicle.svg`;
export const ORANGE_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/orange_cluster.svg`;
export const OTHER_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/other-active.svg`;
export const OTHER_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/other-icon.svg`;
export const OTHER_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/other.svg`;
export const OTHER_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/other-tab.svg`;
export const OTHERS_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/others.svg`;
export const OVERALL_SCORE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/overall-score.svg`;
export const OVERSPEEDING_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/overspeeding.svg`;
export const OVERALL_SCORE_TRENDS = `${cloudFrontURL}/assets/miscellaneous_icons/overall-score-trends.svg`;

export const PAPERCLIP_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/paperclip.png`;
export const PARKING_ACTIVE_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/parking-active-tab.svg`;
export const PARKING_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/parking_default.png`;
export const PARKING_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/parking-tab.svg`;
export const PARKING_SVG = `${cloudFrontURL}/assets/maps/parking.svg`;
export const PATH_SVG = `${cloudFrontURL}/assets/arrows/path.svg`;
export const PATH_5_3X_PNG = `${cloudFrontURL}/assets/arrows/path-5@3x.png`;
export const PATH_6_PNG = `${cloudFrontURL}/assets/arrows/path-6.png`;
export const PDF_SVG = `${cloudFrontURL}/assets/placeholders/pdf.svg`;
export const PEDAL_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/acceleration-pedal-grey-circle.svg`;
export const PHARMACY_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/pharmacy-icon-active.svg`;
export const PHARMACY_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/pharmacy-icon.svg`;
export const PHARMACY_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/pharmacy-map-icon.svg`;
export const PHONE_BLUE_SVG = `${cloudFrontURL}/assets/marketplace/phone-blue.svg`;
export const PHONE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/phone-icon.svg`;
export const PHONE_GREY_CIRCLE_SVG = `${cloudFrontURL}/assets/general_images/phone-grey-circle.svg`;
export const PIE_DARK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/pie-dark.png`;
export const PIE_LIGHT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/pie-light.png`;
export const PIN_PNG = `${cloudFrontURL}/assets/maps/pin.png`;
export const PIN_SVG = `${cloudFrontURL}/assets/maps/pin.svg`;
export const PLACEHOLDER_PNG = `${cloudFrontURL}/assets/placeholders/placeholder.png`;
export const PLAN_D_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Plan-d.svg`;
export const PLAN_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Plan.svg`;
export const PNG_THUMB_PNG = `${cloudFrontURL}/assets/placeholders/png-thumb.png`;
export const POLICE_INSPECTION_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/police-inspection.svg`;
export const POLICE_STATION_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/police-station.svg`;
export const POPUP_DONE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/popup_done.svg`;
export const PRINT_ICON_PNG = `${cloudFrontURL}/assets/subscription/Subscription_Mobile_Images/print_icon.png`;
export const PRINT_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/print.svg`;
export const PROFILE_IMAGE_SVG = `${cloudFrontURL}/assets/placeholders/profile-img.svg`;
export const PROFITABLE_CORRIDOR_EMPTY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/profitable-corridor-empty.svg`;
export const PRASANNA_PURPLE = `${cloudFrontURL}/assets/general_images/prasanna-purple.svg`;
export const PURPLE_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/purple_cluster.svg`;
export const PURPLE_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Purple_Truck.svg`;
export const PURPLE_TRUCK_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Purple_Truck_1.svg`;
export const PLAY_CIRCLE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/play-icon-circle.svg`;
export const PDF_ICON = `${cloudFrontURL}/assets/general_images/Pdf_download_img.svg`;

export const QR_IOS_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/qr-ios.png`;
export const QUESTION_MARK_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/question-mark.svg`;

export const RASH_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/rash.svg`;
export const RASH_TURNING_ALERT_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/Rash_Turning_Alert_Cluster.svg`;
export const RASH_TURNING_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Rash_turning.svg`;
export const RASH_TURNING_GREY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/rash-turning-grey.svg`;
export const REARLIGHTS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/rearlights.svg`;
export const REEFER_SVG = `${cloudFrontURL}/assets/marketplace/refeer.svg`;
export const REFRESH_CLOCK_SVG = `${cloudFrontURL}/assets/marketplace/refresh-clock.svg`;
export const REFRESH_PNG = `${cloudFrontURL}/assets/arrows/refresh.png`;
export const REFRESH_1_PNG = `${cloudFrontURL}/assets/arrows/refresh-1.png`;
export const REFRESH_SVG = `${cloudFrontURL}/assets/arrows/refresh.svg`;
export const RESTAURANTS_PNG = `${cloudFrontURL}/assets/ServicesIcons/restaurants.png`;
export const RESUB_SCREEN_SVG = `${cloudFrontURL}/assets/subscription/resub_screen.svg`;
export const RD_SVG = `${cloudFrontURL}/assets/arrows/rd.svg`;
export const RED_ARROW_PLAN_PNG = `${cloudFrontURL}/assets/arrows/red_arrow_plan.png`;
export const RED_DOT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/redDot.png`;
export const RED_DOT_WITH_WARNING_PNG = `${cloudFrontURL}/assets/general_images/non_applicable_warn_icon.png`;
export const RED_LAMP_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/red-lamp.svg`;
export const FUNNEL_ICON_SVG = `${cloudFrontURL}/assets/general_images/funnel_icon.svg`;
export const CIRCULAR_ADD_BTN = `${cloudFrontURL}/assets/general_images/circular_add_button.svg`;
export const UPDATE_ICON_SVG = `${cloudFrontURL}/assets/general_images/update_icon.svg`;
export const CHART_BAR_STACKED = `${cloudFrontURL}/assets/general_images/chart_bar_stacked.svg`;
export const RESET_GREY_SVG = `${cloudFrontURL}/assets/general_images/reset_grey.svg`;
export const RED_WARNING_ICON_SVG = `${cloudFrontURL}/assets/subscription/red-warning-icon.svg`;
export const RED_ZIG_ZAG_ARROW_SVG = `${cloudFrontURL}/assets/arrows/red-zig-zag-arrow.svg`;
export const REPAIR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/repair.svg`;
export const REGENERATION_DURATION_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/regeneration-duration-1.svg`;
export const REPORT_GREEN_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/report-green.svg`;
export const REPORTING_D_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Reporting-d.svg`;
export const REPORTING_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Reporting.svg`;
export const REQUEST_NO_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/request-no.svg`;
export const RESTAURANT_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/restaurant-map-icon.svg`;
export const RESTAURANT_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/restaurant-icon-active.svg`;
export const RESTAURANT_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/restaurant-icon.svg`;
export const RESTROOM_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/restroom.svg`;
export const REQ_DEMO_ICON_SVG = `${cloudFrontURL}/assets/tata_motors_logos/req_demo_icon.svg`;
export const RIGHT_ARROW_PURPLE_PNG = `${cloudFrontURL}/assets/arrows/right-arrow.png`;
export const RIGHT_ICON_SVG = `${cloudFrontURL}/assets/arrows/right-icon.svg`;
export const RMC_SVG = `${cloudFrontURL}/assets/marketplace/rmc.svg`;
export const ROUTE_MARKER_PNG = `${cloudFrontURL}/assets/maps/route-marker.png`;
export const ROUTE_SVG = `${cloudFrontURL}/assets/arrows/route.svg`;
export const RT_SVG = `${cloudFrontURL}/assets/arrows/rt.svg`;
export const RUPEE_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/rupee-blue.png`;
export const RUPEE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/rupee-icon.svg`;
export const RUPEE_INDIAN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/rupee-indian.png`;
export const RUPEE_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/rupee@3x.png`;
export const RUPEE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/rupee.png`;
// export const BLACK_LEFT_CHEVRON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/black-left-chevron.svg`;
export const BLUE_RIGHT_CHEVRON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/blue-chevron-right.svg`;

export const SALE_1_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/sale-1.png`;
export const SALES_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/sales.svg`;
export const SAAS_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/saas.svg`;
export const SAFETY_PNG = `${cloudFrontURL}/assets/placeholders/safety.png`;
export const SAFETY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/safety.svg`;
export const SAMPLE_AUDIO_MP3 = `${cloudFrontURL}/assets/audio/sample-audio.mp3`;
export const SCHEDULED_SERVICES_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/scheduled-services-plan1.svg`;
export const SCHEDULED_SERVICES_SVG = `${cloudFrontURL}/assets/subscription/your_plan_benefits/scheduled-services.svg`;
export const SCHEDULED_CALENDAR_SVG = `${cloudFrontURL}/assets/general_images/Schedule_calendar.svg`;
export const SEARCH_SVG = `${cloudFrontURL}/assets/maps/search.svg`;
export const SEARCH_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/search-icon.svg`;
export const SEATS_IMAGE_1_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/seatsImage1.png`;
export const SELECTED_PNG = `${cloudFrontURL}/assets/placeholders/selected.png`;
export const SERVICE_HISTORY_SVG = `${cloudFrontURL}/assets/analyse/service-history.svg`;
export const SERVICE_PROFILE_SVG = `${cloudFrontURL}/assets/placeholders/service-profile.svg`;
export const SERVICE_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/service_indication_default.png`;
export const SERVICE_INDICATION_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/service_indication.png`;
export const SERVICE_STATION_PNG = `${cloudFrontURL}/assets/maps/service_station.png`;
export const SERVICESTATION_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Servicestation.svg`;
export const SETTING_SVG = `${cloudFrontURL}/assets/general_images/Settings-icon.svg`;
export const SHAPE_2_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/shape-2@3x.png`;
export const SHAPE_COPY_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/shape-copy@3x.png`;
export const SHAPE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/shape.png`;
export const SHARE_BTN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/share_btn.png`;
export const SHARE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/share-icon.svg`;
export const SHARE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/share.4122.min.png`;
export const SIGNAL_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/signal_amber.png`;
export const SIGNAL_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/signal_default.png`;
export const SIGNAL_GREEN_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/signal_green.png`;
export const SIGNAL_RED_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/signal_red.png`;
export const SKYBLUE_CLUSTER_PNG = `${cloudFrontURL}/assets/maps/skyblue-cluster.png`;
export const SKYBLUE_DOT_PNG = `${cloudFrontURL}/assets/maps/skyblue-dot.png`;
export const SLIDE_1_PNG = `${cloudFrontURL}/assets/tata_motors_logos/Slide1.png`;
export const SLIDER_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/slider-icon-svg.svg`;
export const SMILE_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/smile-blue.png`;
export const SORT_SVG = `${cloudFrontURL}/assets/arrows/sort.svg`;
export const SPEED_GREY_CIRCLE = `${cloudFrontURL}/assets/miscellaneous_icons/speed.svg`;
export const SPLASH_SCREEN_MP4 = `${cloudFrontURL}/assets/videos/splashScreen.mp4`;
export const STATUS_VIEW_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/status-view.svg`;
export const STEP_ONE_PNG = `${cloudFrontURL}/assets/subscription/step-one.png`;
export const STEP_TWO_PNG = `${cloudFrontURL}/assets/subscription/step-two.png`;
export const STEP_THREE_SVG = `${cloudFrontURL}/assets/subscription/STEP_THREE.svg`;
export const STOP_ALERT_ICON_SVG = `${cloudFrontURL}/assets/subscription/stop_alert_icon.svg`;
export const STOP_BLUE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/stop-blue.png`;
export const STOP_YELLOW_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/stop-yellow.png`;
export const STOPPAGE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/stoppage.svg`;
export const STOPPAGE_1_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/stoppage 1.svg`;
export const STOPPAGE_ALERT_CLUSTER_SVG = `${cloudFrontURL}/assets/maps/Stoppage_Alert_Cluster.svg`;
export const STOPPAGE_GREY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/stoppage-grey.svg`;
export const STOPPED_BEAM_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/stopped_beam_icon.svg`;
export const STOPPED_STATE_PNG = `${cloudFrontURL}/assets/maps/stop_state.png`;
export const STOPS_ELLIPSE_300_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/stops_ellipse_300.svg`;
export const SUCCESSFULDOT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/successfuldot.png`;
export const SUCCESS_SVG = `${cloudFrontURL}/assets/general_images/success-image.svg`;

export const SUCCESS_TICK_SVG = `${cloudFrontURL}/assets/subscription/success_tick.svg`;
export const SVG_SVG = `${cloudFrontURL}/assets/placeholders/svg.svg`;
export const SVG_1_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/svg1.svg`;
export const SVG_2_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/svg2.svg`;
export const SVG_3_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/svg3.svg`;
export const SYNCHRONIZATION_ARROWS_PNG = `${cloudFrontURL}/assets/arrows/synchronization-arrows.png`;
export const SPEED_DRIVING_SCORE = `${cloudFrontURL}/assets/miscellaneous_icons/speed.svg`;
export const SEND_MAIL_ICON = `${cloudFrontURL}/assets/general_images/Send_Mail.svg`;

export const TABLE_CLICK_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/table-click-icon.svg`;
export const TABLE_NOT_CLICK_ICON = `${cloudFrontURL}/assets/miscellaneous_icons/table-not-click-icon.svg`;
export const TABLEARROW_SVG = `${cloudFrontURL}/assets/arrows/tablearrow.svg`;
export const TABLE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/table.svg`;
export const TABLE_ICON_SVG = `${cloudFrontURL}/assets/general_images/Table_svg.svg`;
export const TABLE_BACKGROUND_ICON_SVG = `${cloudFrontURL}/assets/general_images/Table_background_svg.svg`;
export const TANKER_SVG = `${cloudFrontURL}/assets/marketplace/tanker.svg`;
export const TASS_IMG_ACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/tass_img_active.svg`;
export const TASS_IMG_INACTIVE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/tass_img_inactive.svg`;
export const TASS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/TASS.svg`;
export const TATA_MOTORS_TATA_STARTBUS_SEATER = `${cloudFrontURL}/assets/vehicle/vehicle_images/tata-motors-tata-starbus-seater.png`;
export const TATA_FLEETEDGE_SVG = `${cloudFrontURL}/assets/tata_motors_logos/tata_fleededge.svg`;
export const TATAMOTORS_PNG = `${cloudFrontURL}/assets/tata_motors_logos/tatamotors.png`;
export const THEFT_AREA_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/theft-area.svg`;
export const THUMBS_UP_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/thums-up.svg`;
export const THUMBS_DOWN_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/thumbs-down.svg`;
export const TICK_1_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/tick-1.svg`;
export const TICK_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/tick.svg`;
export const TIME_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/time.png`;
export const TIMELINE_ANALYTICS_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/timeline_analytics.svg`;
export const TIMELINE_ARROW_SVG = `${cloudFrontURL}/assets/arrows/timeline_arrow.svg`;
export const TIMELINE_USER_SVG = `${cloudFrontURL}/assets/avatars/timeline_user.svg`;
export const TIMELINE_VEH_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/timeline_veh.svg`;
export const TIMER_CIRCLE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/timer-circle.svg`;
export const TIMER_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/timer_icon.svg`;
export const TIMER_PNG = `${cloudFrontURL}/assets/general_images/timer.png`;
export const TIPPER_ICON_SVG = `${cloudFrontURL}/assets/general_images/tipper_icon.svg`;
// export const TM_LOGO = `${cloudFrontURL}/assets/tata_motors_logos/tm-logo.svg`;
export const TMIBASL_LOGO_SVG = `${cloudFrontURL}/assets/tata_motors_logos/tmibasl-logo.svg`;
export const TMIBASL_TOP_BANNER_PNG = `${cloudFrontURL}/assets/general_images/Banner-top-panel4x.png`;
export const TMIBASL_BOTTOM_BANNER_PNG = `${cloudFrontURL}/assets/general_images/Banner-bottom-panel4x.png`;
export const TM_LOADER_GIF = `${cloudFrontURL}/assets/gifs/TM_Loader.gif`;
export const TML_LOGO_SVG = `${cloudFrontURL}/assets/tata_motors_logos/tml-logo.svg`;
export const TOLL_BOOTH_MAP_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/toll-booth-map-icon.svg`;
export const TML_LOGO_LOGIN_SVG = `${cloudFrontURL}/assets/tata_motors_logos/tml-logo-login.svg`;
export const TOOL_BOOTH_ICON_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/tool-booth-icon-active.svg`;
export const TOOL_BOOTH_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/tool-booth-icon.svg`;
export const TOGGLE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/toggle.svg`;
export const TOP_GEAR_GREY_CIRCLE = `${cloudFrontURL}/assets/vehicle/general_icons/top-gear-grey-circle.svg`;
export const TOTAL_ASSET_VALUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/total-asset-value.svg`;
export const TOTAL_DISTANCE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/total-distance.svg`;
export const TOTAL_DISTANCE_TRIP_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/total-distance-trip.svg`;
export const TOTAL_FUEL_PNG = `${cloudFrontURL}/assets/vehicle/general_icons/total_fuel.png`;
export const TOTAL_RUN_HOUR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/total-runhour.svg`;
export const TOTAL_TRIP_TIME_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/total-trip-time.svg`;
export const TOTAL_USERS_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/Total_Users.min.jpg`;
export const TOTALVEHICLECOLORED_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/TotalVehicleColored.min.jpg`;
export const TPMS_TELLTALE_STATUS_INDICATION_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/tpms_telltale_status_indication_default.png`;
export const TRACK_4122_MIN_WEBP = `${cloudFrontURL}/assets/images/landing-page/Track.4122.min.webp`;
export const TRACK_CREATE_TRIP_SVG = `${cloudFrontURL}/assets/maps/track_create_trip.svg`;
export const TRACK_GEOFENCE_SVG = `${cloudFrontURL}/assets/maps/track_geofence.svg`;
export const TRACK_MIN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Track.min.png`;


export const TRACK_SIDEBAR_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/track-icon.svg`;
export const TRACK_SIDEBAR_SELECTED = `${cloudFrontURL}/assets/miscellaneous_icons/track-selected.svg`;
export const TRACK_D_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Track-d.svg`;
export const TRACK_ICON_SVG = `${cloudFrontURL}/assets/maps/track-icon.svg`;
export const TRACK_SHARE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/track_share.svg`;
export const TRACK_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/Track.svg`;
export const TRACK_FLEET_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/track_fleet.svg`;
export const TRACK_OCCUPANCY_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/track_occupancy.svg`;
export const TRACK_ON_TRIP_EMPTY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/track-on-trip-empty.svg`;
export const TRACK_VEHICLE_DIC_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/track_vehicle_dic.svg`;
export const TRACK_VEHICLE_TRACKING_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/track_vehicle_tracking.svg`;
export const TRACK_CROSS_HAIR_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/track-crosshair.svg`;

export const TRIP_DRAFT_ARROW_SVG = `${cloudFrontURL}/assets/arrows/trip-draft-arrow.svg`;
export const TRIP_EMPTY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/trip-empty.svg`;
export const TRIP_PLAN_1_SVG = `${cloudFrontURL}/assets/subscription/gold-plat-icons/trip-plan1.svg`;
export const TRIP_WISE_REPORT_SVG = `${cloudFrontURL}/assets/analyse/trip_wise_report.svg`;
export const TRIP_VIEW_STATUS_SVG = `${cloudFrontURL}/assets/general_images/trip-view-status.svg`;
export const TRIP_STATUS = `${cloudFrontURL}/assets/general_images/Trip-progress.svg`;
export const TRUCK_ICON_PLAYBACK_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/truck-icon-playback.png`;
export const TRUCK_ICON_PNG = `${cloudFrontURL}/assets/general_images/truckIcon.png`;
export const TRUCK_ICON_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/truck_icon.svg`;
export const TRUCK_JPG = `${cloudFrontURL}/assets/vehicle/vehicle_images/truck.jpg`;
export const TRUCK_LOADING_IMG_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Truck-loading-img.png`;
export const TWENTYFOUR_HOUR_FLEET_REPORT_SVG = `${cloudFrontURL}/assets/general_images/24_hour_fleet_report.svg`;
export const TWITTER_SVG = `${cloudFrontURL}/assets/social_logos/Twitter.svg`;
export const TWO_VEHICLES_GREY_BG_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/two-vehicles-grey-bg.svg`;
export const TWO_GREY_PINS_SVG = `${cloudFrontURL}/assets/maps/two-grey-pins.svg`;
export const TYRE_MANAGEMENT_SVG = `${cloudFrontURL}/assets/marketplace/Tyre_Management.svg`;
export const TYREPRESSURELOW_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/tyrepressurelow.svg`;
export const TYRE_MANAGEMENT_NO_RECORDS_IMAGE = `${cloudFrontURL}/assets/tyre-management/tyre-management-no-records-image.svg`;
export const TELECALLER_HEADPHONE_ICON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/telecaller.svg`;
export const UNALLOCATED_ORANGE_DOT_PNG = `${cloudFrontURL}/assets/maps/unallocated-orange-dot.png`;
export const UNLINKED_VEHICLE_ICON_SVG = `${cloudFrontURL}/assets/subscription/unlinked_vehicle_icon.svg`;
export const UNLOADING_AREA_ACTIVE_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/unloading-area-active.svg`;
export const UNLOADING_AREA_SVG = `${cloudFrontURL}/assets/maps/unloading-area.svg`;
export const UNLOADING_TAB_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/unloading-tab.svg`;
export const UP_ARROW_SVG = `${cloudFrontURL}/assets/images/buses/booking/up_arrow.svg`;
export const UP_ARROW_2_PNG = `${cloudFrontURL}/assets/arrows/upArrow (2).png`;
export const UPARROW_PNG = `${cloudFrontURL}/assets/arrows/UpArrow.png`;
export const UP_ICON_SVG = `${cloudFrontURL}/assets/arrows/up-icon.svg`;
export const UPGRADE_ARROW_SVG = `${cloudFrontURL}/assets/arrows/upgrade-arrow.svg`;
export const UPLOAD_BLUE_SVG = `${cloudFrontURL}/assets/general_images/upload.svg`;
export const UPLOAD_SVG = `${cloudFrontURL}/assets/marketplace/upload.svg`;
export const UPLOAD_4_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/upload-4.png`;
export const UP_PNG = `${cloudFrontURL}/assets/arrows/up.png`;
export const USER_EMPTY_SVG = `${cloudFrontURL}/assets/placeholders/user-empty.svg`;
export const USER_MANAGEMENT_SVG = `${cloudFrontURL}/assets/placeholders/user-management.svg`;
export const USER_PLACEHOLDER_PNG = `${cloudFrontURL}/assets/placeholders/user-placeholder.png`;
export const USER_PIC_PNG = `${cloudFrontURL}/assets/placeholders/user-pic.png`;
export const USEREVENTS_MIN_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/UserEvents.min.jpg`;
export const UPLOAD_ARROW_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/upload-arrow.svg`;
export const UPTIME_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/uptime.svg`;

export const VECTOR_JPG = `${cloudFrontURL}/assets/miscellaneous_icons/vector.jpg`;
export const VECTOR_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vector.svg`;
export const VECTOR_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Vector.png`;
export const VEH_HEALTH_INDICATORS_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/veh-health-indicators.svg`;
export const VEHICLE_DAY_WISE_TRACKER_SVG = `${cloudFrontURL}/assets/analyse/vehicle_day_wise_tracker.svg`;
// export const VEHICLE_HEALTH_INDICATOR_GREY_CIRCLE = `${cloudFrontURL}/assets/vehicle/general_icons/vehicle-health-indicator-grey-circle.svg`;
export const VEHICLE_IDLE_PLAN_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehcile-idle-plan1.svg`;
export const VEHICLE_IDLING_FUEL_LOSS_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-idling-fuel-loss.svg`;
export const VEHICLE_IDLING_GREY_CIRCLE = `${cloudFrontURL}/assets/vehicle/general_icons/idling-vehicle-grey-circle.svg`;
export const VEHICLE_INSURANCE_ICON_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-insurance-icon.svg`;
export const VEHICLE_INSURANCE_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Vehicle_Insurance.svg`;
export const VEHICLE_OFFLINE_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/vehicle-offline.png`;
export const VEHICLE_PLACEHOLDER_PNG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-placeholder.png`;
export const VEHICLE_SCORE_CARDS_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-score-cards.svg`;
export const VEHICLE_TRACKING_NEW_4122_MIN_WEBP = `${cloudFrontURL}/assets/miscellaneous_icons/Vehicle_tracking-new.4122.min.webp`;
export const VEHICLE_TRACKING_NEW_MIN_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/Vehicle_tracking-new.min.png`;
export const VEHICLE_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-truck.svg`;
export const VEHICLE_MANAGEMENT_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicle-management.svg`;
export const VEHICLE_USAGE_SVG = `${cloudFrontURL}/assets/analyse/vehicle_usage.svg`;
export const VEHICLES_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/vehicles.svg`;
export const VENDOR_EMPTY_SVG = `${cloudFrontURL}/assets/placeholders/vendor-empty.svg`;
export const VENDOR_MANAGEMENT_SVG = `${cloudFrontURL}/assets/avatars/vendor-management.svg`;
export const VERTICAL_LINE_PNG = `${cloudFrontURL}/assets/subscription/vertical-line.png`;
export const VERTICAL_PLAN_LINE_SVG = `${cloudFrontURL}/assets/subscription/vertical_plan_line.svg`;
export const VERIFIED_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/verified.svg`;
export const VIA_MARKER_PNG = `${cloudFrontURL}/assets/maps/via-marker.png`;
export const VICE_VERSA_SVG = `${cloudFrontURL}/assets/arrows/vice-versa.svg`;
export const VISIT_US_NOW = `${cloudFrontURL}/assets/general_images/visist-us-now.png`;
export const PLAY_BUTTON_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/video-play-btn.svg`;
export const VOD_ICON_SVG = `${cloudFrontURL}/assets/vehicle/general_icons/vod.png`;
export const VALUE_LOCK_SVG = `${cloudFrontURL}/assets/general_images/lock.svg`;


export const WALLETS_SVG = `${cloudFrontURL}/assets/marketplace/wallets.svg`;
export const WARNING_HEALTH_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/warning-health.svg`;
export const WARNING_ICON_SVG = `${cloudFrontURL}/assets/subscription/warning-icon.svg`;
export const WHITE_CROSS_SVG = `${cloudFrontURL}/assets/general_images/white-cross.svg`;
export const REMINDER_ICON_SVG = `${cloudFrontURL}/assets/subscription/Reminder.svg`;

export const WATER_IN_FUEL_AMBER_PNG = `${cloudFrontURL}/assets/vehicle/issue_types/water_in_fuel_amber.png`;
export const WATER_IN_FUEL_DEFAULT_PNG = `${cloudFrontURL}/MobileUploads/TellTales/water_in_fuel_default.png`;
export const WAY_1_SVG = `${cloudFrontURL}/assets/vehicle/issue_types/water_in_fuel_amber.png`;
export const WHITE_DOWNLOAD = `${cloudFrontURL}/assets/vehicle/general_icons/white-download.svg`;

export const YELLOW_DOT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/yellowDot.png`;
export const YELLOW_TRUCK_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Yellow_Truck.svg`;
export const YELLOW_TRUCK_1_SVG = `${cloudFrontURL}/assets/vehicle/vehicle_images/Yellow_Truck_1.svg`; /// version 12
export const YOUTUBE_SVG = `${cloudFrontURL}/assets/social_logos/Youtube.svg`;

export const WALLET_DARK_BLUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/wallet-drak-blue.svg`;
export const WATCH_DARK_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/watch-dark.png`;
export const WATCH_LIGHT_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/watch-light.png`;
export const WAY_1_3X_PNG = `${cloudFrontURL}/assets/miscellaneous_icons/way-1@3x.png`;

export const TICK_ICON = `${cloudFrontURL}/assets/consent-management/tick-icon.svg`;
export const STORAGE_ICON = `${cloudFrontURL}/assets/consent-management/storage-icon.svg`;
export const SHIELD_ICON = `${cloudFrontURL}/assets/consent-management/shield-icon.svg`;
export const FILTER_ICON = `${cloudFrontURL}/assets/consent-management/filter-icon.svg`;
export const EXCLAMATION_ICON = `${cloudFrontURL}/assets/consent-management/exclamation-icon.svg`;
export const CROSS_ICON = `${cloudFrontURL}/assets/consent-management/cross-icon.svg`;
export const MAP_PLACEHOLDER_IMAGE = `${cloudFrontURL}/assets/consent-management/map-placeholder-image.svg`;
export const SPARKLING_STAR_ICON = `${cloudFrontURL}/assets/consent-management/sparkling-star-icon.svg`;
export const FILLED_STORAGE_ICON = `${cloudFrontURL}/assets/consent-management/filled-storage-icon.svg`;
export const FILLED_SHIELD_ICON = `${cloudFrontURL}/assets/consent-management/filled-shield-icon.svg`;

// api-framework
export const AMS_ALERT_SVG = `${cloudFrontURL}/assets/api_management/alert.svg`;
export const AMS_STANDARD_SVG = `${cloudFrontURL}/assets/api_management/standard.svg`;
export const AMS_TIMEBOUND_SVG = `${cloudFrontURL}/assets/api_management/timebound.svg`;
export const AMS_MAIN_CARD_SVG = `${cloudFrontURL}/assets/api_management/AMS.svg`;
export const AMS_KEY_STEP_1_SVG = `${cloudFrontURL}/assets/api_management/step1.svg`;
export const AMS_KEY_STEP_2_SVG = `${cloudFrontURL}/assets/api_management/step2.svg`;
export const AMS_KEY_STEP_3_SVG = `${cloudFrontURL}/assets/api_management/step3.svg`;
export const AMS_GREEN_CHCEK_SVG = `${cloudFrontURL}/assets/api_management/greenCheck.svg`;
export const AMS_EDIT_SVG = `${cloudFrontURL}/assets/api_management/edit.svg`;
export const AMS_VEHICLE_SVG = `${cloudFrontURL}/assets/api_management/vehicle.svg`;
export const AMS_SLIDER_PNG = `${cloudFrontURL}/assets/api_management/slider.png`;
export const AMS_CUSTOM_API_STEP_1_SVG = `${cloudFrontURL}/assets/api_management/custom-api-step-1.svg`;
export const AMS_CUSTOM_API_STEP_2_SVG = `${cloudFrontURL}/assets/api_management/custom-api-step-2.svg`;
export const AMS_COMING_SOON_SVG = `${cloudFrontURL}/assets/api_management/comingSoon.svg`;
export const AMS_FEAUTRE_COMING_SOON_SVG = `${cloudFrontURL}/assets/api_management/feature-coming-soon.svg`;
export const AMS_HORIZONTAL_DOTTED_LINE_SVG = `${cloudFrontURL}/assets/api_management/horizontal-line.svg`;
export const AMS_STEP1_PAGE1_SVG = `${cloudFrontURL}/assets/api_management/step1Page1.svg`;
export const AMS_STEP2_PAGE1_SVG = `${cloudFrontURL}/assets/api_management/step2Page1.svg`;
export const AMS_STEP3_PAGE1_SVG = `${cloudFrontURL}/assets/api_management/step3Page1.svg`;
export const AMS_STEP1_PAGE2_SVG = `${cloudFrontURL}/assets/api_management/step1Page2.svg`;
export const AMS_STEP2_PAGE2_SVG = `${cloudFrontURL}/assets/api_management/step2Page2.svg`;
export const AMS_STEP3_PAGE2_SVG = `${cloudFrontURL}/assets/api_management/step3Page2.svg`;
export const AMS_STEP1_PAGE3_SVG = `${cloudFrontURL}/assets/api_management/step1Page3.svg`;
export const AMS_STEP2_PAGE3_SVG = `${cloudFrontURL}/assets/api_management/step2Page3.svg`;
export const AMS_STEP3_PAGE3_SVG = `${cloudFrontURL}/assets/api_management/step3Page3.svg`;
export const AMS_CROSS_SVG = `${cloudFrontURL}/assets/api_management/cross.svg`;
export const APD_PDF = `${cloudFrontURL}/assets/pdf/downloaded-access-product-document.pdf`;

// fuel-management-solution-REPOS
export const FMS_COMPLETE_SAFETY_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-complete-Safety.svg`;
export const FMS_FASTER_REFULLING_TIME1_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-faster-refuelling-time1.svg`;
export const FMS_FASTER_REFULLING_TIME2_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-faster-refuelling-time2.svg`;
export const FMS_MONITOR_FUEL_EFFORTLESSLY_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-monitor-fuel-efforlessly.svg`;
export const FMS_ON_SITE_FUEL_AVAILABILITY_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-on-site-fuel-availabilty.svg`;
export const FMS_PREVENTION_ON_FUEL_THEFT1_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-prevention-of-fuel-theft1.svg`;
export const FMS_PREVENTION_ON_FUEL_THEFT2_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-prevention-of-fuel-theft2.svg`;
export const FMS_PREVENTION_ON_FUEL_THEFT3_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-prevention-of-fuel-theft3.svg`;
export const FMS_QUALITY_AND_QUANTITY_BIG_PICTURE = `${cloudFrontURL}/assets/fuel_management_solution/bigPicture-quality-and-quantity-assurance.svg`;
export const FMS_BLUE_TOOLTIP = `${cloudFrontURL}/assets/fuel_management_solution/blueTultip.svg`;
export const FMS_CLOSE_INVERTED_COMMAS = `${cloudFrontURL}/assets/fuel_management_solution/closeInvertedCommas.svg`;
export const FMS_COMPLETE_SAFETY = `${cloudFrontURL}/assets/fuel_management_solution/complete-Safety.svg`;
export const FMS_FUEL_REFULLING_TIME = `${cloudFrontURL}/assets/fuel_management_solution/faster-refuelling-time.svg`;
export const FMS_FUEL_CONSTRUCTION = `${cloudFrontURL}/assets/fuel_management_solution/fuelConstruction.svg`;
export const FMS_FUEL_MINNING = `${cloudFrontURL}/assets/fuel_management_solution/fuelMinning.svg`;
export const FMS_FUEL_SERVICES = `${cloudFrontURL}/assets/fuel_management_solution/fuelServices.svg`;
export const FMS_MAIN_LOGO = `${cloudFrontURL}/assets/fuel_management_solution/mainLogo.svg`;
export const FMS_MONITOR_FUEL_EFFORTLESSLY = `${cloudFrontURL}/assets/fuel_management_solution/monitor-fuel-efforlessly.svg`;
export const FMS_ON_SITE_FUEL_AVAILABILITY = `${cloudFrontURL}/assets/fuel_management_solution/on-site-fuel-availability.svg`;
export const FMS_OPEN_INVERTED_COMMAS = `${cloudFrontURL}/assets/fuel_management_solution/openInvertedCommas.svg`;
export const FMS_PREVENTION_OF_FUEL_THEFT = `${cloudFrontURL}/assets/fuel_management_solution/prevention-of-fuel-theft.svg`;
export const FMS_QUALITY_AND_QUANTITY_ASSURANCE = `${cloudFrontURL}/assets/fuel_management_solution/quality-and-quantity-assurance.svg`;
export const FMS_REPOS_PRODUCT = `${cloudFrontURL}/assets/fuel_management_solution/reposProduct.svg`;
export const FMS_START_IMG = `${cloudFrontURL}/assets/fuel_management_solution/starImg.svg`;
export const FMS_SUCCESS_SIGN = `${cloudFrontURL}/assets/fuel_management_solution/successSign.svg`;
export const FMS_TRUCK_WITH_MACHINE = `${cloudFrontURL}/assets/fuel_management_solution/trckWIthMachine.svg`;
export const FMS_WHITE_TOOLTIP = `${cloudFrontURL}/assets/fuel_management_solution/whiteTultip.svg`;
export const FMS_YOU_TUBE_ICON = `${cloudFrontURL}/assets/fuel_management_solution/youTubeIcon.svg`;
// export const FMS_CCTV_SURVELLIANCE = `${cloudFrontURL}/assets/fuel_management_solution/CCTVSurveillance.svg`;
export const FMS_DATUM_Z_1000L = `${cloudFrontURL}/assets/fuel_management_solution/DATUM-Z-1000L.svg`;
export const FMS_DATUM_Z_2000L = `${cloudFrontURL}/assets/fuel_management_solution/DATUM-Z-2000L.svg`;
export const FMS_EXPERIENCE_SVG = `${cloudFrontURL}/assets/fuel_management_solution/experience.svg`;
export const FMS_CALL_SVG = `${cloudFrontURL}/assets/fuel_management_solution/fi-rr-call-history.svg`;
export const FMS_MAIL_SVG = `${cloudFrontURL}/assets/fuel_management_solution/fi-rr-envelope.svg`;

// export const FMS_FUEL_LEVEL_SENSOR = `${cloudFrontURL}/assets/fuel_management_solution/fuelLevelSensor.svg`;
export const FMS_FUEL_LEVEL_SENSOR = `${cloudFrontURL}/assets/marketplace/automatic-fuel-level-sensor.png`;
export const FMS_CCTV_SURVELLIANCE = `${cloudFrontURL}/assets/marketplace/cctv.png`;
export const FMS_SUB_ASSETS = `${cloudFrontURL}/assets/marketplace/sub-asset-analytics.png`;
export const FMS_MOBILE_LEVEL_ACCESS = `${cloudFrontURL}/assets/marketplace/mobile-app-access.png`;

// export const FMS_SUB_ASSETS = `${cloudFrontURL}/assets/fuel_management_solution/sub-asset.svg`;
export const FMS_DESPENCING_MACHANISM = `${cloudFrontURL}/assets/marketplace/dispensing-mechanism.png`;

// custom Weightage
export const RESET_DARK_BLUE_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/reset.svg`;
export const RESET_LIGHT_GREY_SVG = `${cloudFrontURL}/assets/miscellaneous_icons/reset-light-grey.svg`;
export const COPY_BLUE_ICON = `${cloudFrontURL}/assets/miscellaneous_icons/copy_blue_stroke_icon.svg`

// export const FMS_SUB_ASSETS = `${cloudFrontURL}/assets/fuel_management_solution/sub-asset.svg`;
// VLTD AFTER MARKET TCU
export const TCU_IMG = `${cloudFrontURL}/after_market_tcu/tcu_img.svg`;
export const VLTD_EDIT_ICON = `${cloudFrontURL}/after_market_tcu/vltd_edit.svg`;
export const VLTD_EDIT_DISABLE_ICON = `${cloudFrontURL}/after_market_tcu/vltd_edit_disable.svg`;
export const VLTD_DOWNLOAD_SVG = `${cloudFrontURL}/after_market_tcu/download_icon.svg`;
export const VLTD_AFTER_MARKET_TCU_ICON = `${cloudFrontURL}/after_market_tcu/vltd_after_market_tcu_icon.svg`;
export const EMAIL_BLUE_ICON_SVG = `${cloudFrontURL}/after_market_tcu/icon_mail.svg`;
export const VLTD_VEHICLE_ICON_RED = `${cloudFrontURL}/after_market_tcu/vehicle_icon.svg`;
export const VLTD_LOCATION_ICON = `${cloudFrontURL}/after_market_tcu/icon_location.svg`;
export const VLTD_PLAN_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/plan_icon.svg`;
export const VLTD_TRACK_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/track_icon.svg`;
export const VLTD_ANALYSE_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/analyse_icon.svg`;
export const VLTD_INSIGHT_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/insight_icon.svg`;
export const VLTD_VEHICLE_TRACING_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/vehicle_tracing_icon.svg`;
export const VLTD_INCIDENTS_ICON_GREY_40 = `${cloudFrontURL}/after_market_tcu/incident_alerts_icon.svg`;
export const VLTD_VEHICLE_TRACING_ICON_BLUE = `${cloudFrontURL}/after_market_tcu/selected_vehicle_tracing_icon.svg`;
export const VLTD_INCIDENTS_ICON_BULE = `${cloudFrontURL}/after_market_tcu/selected_incident_alert_icon.svg`;
export const VLTD_PLAN_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/plan_frame.svg`;
export const VLTD_TRACK_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/track_frame.svg`;
export const VLTD_ANALYSE_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/analyse_frame.svg`;
export const VLTD_INSIGHT_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/insight_frame.svg`;
export const VLTD_VEHICLE_TRACING_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/vehicle_tracing_frame.svg`;
export const VLTD_INCIDENT_AND_ALERTS_IMAGE_FRAME = `${cloudFrontURL}/after_market_tcu/incidents_alert_frame.svg`;
export const VLTD_FEATURE_ANNOUNCEMENT_MODAL_MAP = `${cloudFrontURL}/after_market_tcu/background-map.svg`;
export const PENDING_STATUS_ICON = `${cloudFrontURL}/after_market_tcu/pending_status_icon.svg`;
export const THREE_DOTS_ICON = `${cloudFrontURL}/assets/miscellaneous_icons/3dot-download.svg`;

// Subscription
export const PARTIAL_SUBSCRIPTION_ANNOUNCEMENT = `${cloudFrontURL}/assets/subscription/partial_subscription_announcement_1.png`;
export const PARTIAL_SUBSCRIPTION_ANNOUNCEMENT_RESPONSIVE = `${cloudFrontURL}/assets/subscription/partial_subscription_announcement_responsive_1.png`;
export const PARTIAL_UPGRADE_ICON = `${cloudFrontURL}/assets/subscription/partial_upgrade_icon.svg`;
export const PLAN_EXTENSION_ICON = `${cloudFrontURL}/assets/subscription/plan_extension_icon.svg`;
export const MANAGING_SUBSCRIPTION_PLAN_IMAGE = `${cloudFrontURL}/assets/subscription/managing_subscription_plans_img.svg`;
export const MILEAGE_SAARATHI_PLAN_IMAGE = `${cloudFrontURL}/assets/subscription/explore_mileage_saarathi_img.svg`;

// WhatsApp Subscribe
export const WHATSAPP_ICON = `${cloudFrontURL}/assets/general_images/whatspp-icon.svg`;
// Tyre Management As A service
export const AIR_STICK = `${cloudFrontURL}/assets/tyre-management/airstick.svg`;
export const DASHBOARD = `${cloudFrontURL}/assets/tyre-management/dashboard.svg`;
export const NSD = `${cloudFrontURL}/assets/tyre-management/nsd.svg`;
export const TYRE_STEP_ONE = `${cloudFrontURL}/assets/tyre-management/step-1.svg`;
export const TYRE_STEP_TWO = `${cloudFrontURL}/assets/tyre-management/step-2.svg`;
export const TYRE_STEP_THREE = `${cloudFrontURL}/assets/tyre-management/step-3.svg`;
export const TYRE_CARE = `${cloudFrontURL}/assets/tyre-management/tyre-care.svg`;
export const TYRE_MANAGEMENT_MAIN_ICON = `${cloudFrontURL}/assets/tyre-management/tyre-management-main-icon.svg`;
export const TYRE_MANAGEMENT_LINE = `${cloudFrontURL}/assets/tyre-management/Line.svg`;
export const YELLOW_WARNING_ICON = `${cloudFrontURL}/assets/tyre-management/yellow-popup-icon.svg`;
export const CONTACT_ICON = `${cloudFrontURL}/assets/tyre-management/contact-icon.svg`;
export const NO_RECORD_FOUND_VEHICLE_IMG = `${cloudFrontURL}/assets/tyre-management/vehicle-alert.svg`;


// Mileage Saarathi Revamp (FE Consulting)
export const NO_MILEAGE_SAARATHI_VEHICLES = `${cloudFrontURL}/assets/mileage-saarathi-revamp/no-mileage-saarathi-vehicles.svg`;
export const NOTE_IMAGE = `${cloudFrontURL}/assets/mileage-saarathi-revamp/note-img.svg`;
export const RAISE_QUERY = `${cloudFrontURL}/assets/mileage-saarathi-revamp/raise-query.svg`;
export const AVERAGE_PERFORMING_VEHICLE_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/average-performing-vehicle-icon.svg`;
export const FE_TRACKING_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/tracking-icon.svg`;
export const FE_FOCUS_PARAMETER_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/focus-parameter-icon.svg`;
export const FE_VEHICLE_ALERT_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/vehicle-alert-icon.svg`;
export const FE_TRIP_PLANING_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/trip-planing-icon.svg`;
export const FE_TRAINING_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/training-icon.svg`;
export const FE_CUSTOMER_SUPPORT_ICON = `${cloudFrontURL}/assets/mileage-saarathi-revamp/customer-support-icon.svg`;



export const imagepath = {
    vehicleDefaultImageLink: VEHICLE_PLACEHOLDER_PNG,
    defaultRedMarkerIcon: DESTINATION_MARKER_RED_PNG,
    offlineIcon: GREY_TRUCK_1_SVG,
    offlineBeamIcon: OFFLINE_BEAM_ICON_SVG,
    movingIcon: GREEN_TRUCK_1_SVG,
    movingIconBeam: MOVING_BEAM_ICON_SVG,
    idlingIcon: YELLOW_TRUCK_1_SVG,
    idlingIconBeam: IDLING_BEAM_ICON_SVG,
    stoppedIcon: PURPLE_TRUCK_1_SVG,
    stoppedBeamIcon: STOPPED_BEAM_ICON_SVG,
};
