import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { ASCENDING_GREY_SVG, ASCENDING_SVG, DESCENDING_GREY_SVG, DESCENDING_SVG } from '@shared/configurations/image-constants';

/*
provide table data as array of objects, each object representing one row.
provide table columns as array of objects implementing the column interface
if table has a custom column(eg: each cell of a column needs to have a button) provide template and key to identify particular column through customcelltemplate input
*/
@Component({
  selector: 'app-query-result',
  templateUrl: './query-result.component.html',
  styleUrls: ['./query-result.component.scss']
})
export class QueryResultComponent implements OnInit, OnChanges {

  showTableData : Boolean = false;
  waterMarkLabel = localStorage.getItem('user_id');

  ASCENDING_GREY_SVG = ASCENDING_GREY_SVG;
  ASCENDING_SVG = ASCENDING_SVG;
  DESCENDING_SVG = DESCENDING_SVG;
  DESCENDING_GREY_SVG = DESCENDING_GREY_SVG;

  @Input()
  paginationCounterVisbibe = true;

  @Input()
  watermarkEnabled = true;

  @Input()
  tableData : {}[] = [{}]; 

  @Input()
  totalRecords : number; 

  @Input()
  currentPage : number; 

  @Input()
  columnData : Column[]

  @Output()
  pageChange : EventEmitter<number> = new EventEmitter<number>() ;  

  // @Input()
  // customCellTemplates : {colKey : string, template :TemplateRef<any> }[] ;
  @Input()
  customCellTemplates : {} ; //colKey:templateRef
  //
  // viewLocation=false;
  // latitude:any;
  // longititude:any;
  @Output() getUpDownChange = new EventEmitter<any>();

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {

    if(changes.tableData)
    {
      this.tableData =  changes["tableData"].currentValue ;
      // this.latitude=changes["tableData"].currentValue[0].gpsLatitude;
      // this.longititude=changes["tableData"].currentValue[0].gpsLongitude;

      // this.viewLocation=true;

      // if(this.tableData.length>0)
      // {
      //   this.showTableData=true
      // }   
    }

    if(changes["columnData"])
    {
      this.columnData = changes["columnData"].currentValue ;
    }

    if(changes["currentPage"])
    {
      this.currentPage=changes["currentPage"].currentValue
    }

    if(changes["customCellTemplates"])
    {
      this.customCellTemplates=changes["customCellTemplates"].currentValue
    }
    
    if(changes["watermarkEnabled"])
    {
      this.watermarkEnabled=changes["watermarkEnabled"].currentValue
    }
  }

  ngOnInit() {}

  onPaginationEvent(pageNumber : number)
  {
    this.pageChange.emit(pageNumber)
  }

  getUpDownChanged(value, key) {
    const sortingValue = {
      value,
      key
    };
    this.getUpDownChange.emit(sortingValue);
  }

}