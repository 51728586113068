import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import { ConfigService } from 'src/app/config.service';
import { appLevelConstants } from '@shared/configurations/app-level-constants';

@Injectable({
  providedIn: 'root'
})
export class VehicleListService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  httpOptions = this.configService.getApplicationHeaders();
  addhttpOptions = this.configService.getAddEditHeaders();

  // API call for fetching vehicle list
  getVehicleData(userData): Observable<any> {
    return this.http.post(urlConstants.VEHICLE_LIST_API, JSON.stringify(userData), this.getAddEditHeaders());
  }

  getLocationName(userData): Observable<any> {
    return this.http.post(urlConstants.API_GET_PLACES_ON_MAP, JSON.stringify(userData), this.getAddEditHeaders());
  }

  getAddressStateData(): Observable<any> {
    return this.http.post(appLevelConstants.GET_STATE_URL, {}, this.configService.getApplicationHeaders());
  }

  getAddressDistrictByState(state): Observable<any> {
    return this.http.post(appLevelConstants.GET_DISTRICT_URL, state, this.configService.getApplicationHeaders());
  }

  getAddressCitiesByStateDistrict(district): Observable<any> {
    return this.http.post(appLevelConstants.GET_CITY_URL, district, this.configService.getApplicationHeaders());
  }

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }

  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }

  getOffboardingHistory(data) {
    return this.http.post(urlConstants.OFFBOARDING_HISTORY_API, JSON.stringify(data), this.getAddEditHeaders());
  }

  offboardingVehicle(data) {
    return this.http.post(urlConstants.VEHICLE_OFFBOARD_API, JSON.stringify(data), this.getAddEditHeaders());
  }
}
