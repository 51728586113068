import { Component, ContentChildren, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { InputGroupDirective } from './input-group.directive';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit,OnChanges {

  @ContentChildren(InputGroupDirective) inputGroups: QueryList<InputGroupDirective>;
  @Input()
  title : string = "" ;

  @Input()
  allowDownload : boolean = true ;

  @Input()
  allowRefresh : boolean = false ;

  @Input()
  disclaimer : string = "";

  @Input() showSearchButton:boolean = true;

  @Output()
  query : EventEmitter<any> = new EventEmitter<any>() ;

  @Output()
  download : EventEmitter<any> = new EventEmitter<any>() ;

  @Output()
  refresh : EventEmitter<any> = new EventEmitter<any>() ;

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes["allowDownload"])
    {
      this.allowDownload = changes["allowDownload"].currentValue;
    }
    if(changes["allowRefresh"])
    {
      this.allowRefresh = changes["allowRefresh"].currentValue;
    }
    if(changes["disclaimer"])
    {
      this.disclaimer = changes["disclaimer"].currentValue;
    }
    if(changes["title"])
    {
      this.title = changes["title"].currentValue;
    }
  }

  ngOnInit() {
  }

  submit(type:string)
  {
    switch(type)
    {
      case "query" :
      {
        this.query.emit({});
        break; 
      }
      case "download" :
      {
        this.download.emit({});
        
        break; 
      }
      case "refresh" :
      {
        this.refresh.emit({});
        break; 
      }
    }
    
  }


}